
import Vue from "vue";
import { Component } from "vue-property-decorator";

import {
  InputComponent,
  ButtonComponent,
  GeneralTableComponent,
  DatePicker,
  TimePicker,
} from "@procesio/procesio-design-system";

import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Modal from "@/components/Modal/Modal.vue";
import { filterUIT } from "@/services/Consignment";

Vue.directive("click-outside", {
  bind: function (el: any, binding: any, vnode: any) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el: any) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

@Component({
  components: {
    Modal,
    "pds-input": InputComponent,
    "pds-button": ButtonComponent,
    "pulse-loader": PulseLoader,
    "pds-table": GeneralTableComponent,
    "pds-date-picker": DatePicker,
    "pds-time-picker": TimePicker,
  },
})
export default class TransportView extends Vue {
  tableConfig: {
    columns: Array<{ key: string; label: string; show: boolean }>;
    items: Array<any>;
    pagination: any;
  } = {
    columns: [
      {
        key: "consignmentId",
        label: "Consignment id",
        show: true,
      },
      {
        key: "collectionDate",
        label: "Collection date",
        show: true,
      },
      {
        key: "uitStatus",
        label: "UIT STATUS",
        show: true,
      },
      {
        key: "uitCodes",
        label: "UIT codes (per pallets)",
        show: true,
      },
    ],
    items: [
      // {
      //   consignmentId: "12344",
      //   collectionDate: "6 January 2021 12:28:12",
      //   uitStatus: "3/4",
      //   uitCodes: [
      //     "01-6A1V7J1E3R2C1351",
      //     "02-6A1V7J1E3R2C1352",
      //     "03-generating",
      //   ],
      // },
    ],
    pagination: {
      total: 0,
      pageTotal: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
  };

  filter = {
    consignmentId: "",
    dates: {
      start: new Date(),
      end: this.addDays(new Date(), 1),
    },
  };

  isDatesVisible = false;

  codRod: string | (string | null)[] = "";

  clientId: string | (string | null)[] = "";

  isLoading = false;

  get dates() {
    return `${this.filter.dates.start.toLocaleDateString()} - ${this.filter.dates.end.toLocaleDateString()}`;
  }

  mounted() {
    const { codrod, clientid } = this.$route.query;

    this.codRod = codrod;

    this.clientId = clientid;
  }

  focusHandler() {
    if (this.isDatesVisible) {
      return;
    }

    this.isDatesVisible = true;
  }

  blurHandler() {
    if (!this.isDatesVisible) {
      return;
    }

    this.isDatesVisible = false;
  }

  addDays(date: string | number | Date, days: number) {
    const result = new Date(date);

    result.setDate(result.getDate() + days);

    return result;
  }

  onFilter() {
    this.isLoading = true;

    filterUIT(
      {
        consignmentId: this.filter.consignmentId,
        startDate: this.filter.dates.start,
        endDate: this.filter.dates.end,
        codRod: (this.codRod as string)?.toUpperCase(),
        clientId: this.clientId,
        currentPage: this.tableConfig.pagination.currentPage - 1,
        perPage: this.tableConfig.pagination.perPage,
      },
      "Pagination"
    )
      .then((resp: any) => {
        console.log(resp);
        const { items, pagination } = resp;

        this.tableConfig.items = [...items];
        this.tableConfig.pagination.total = pagination.total;
        this.tableConfig.pagination.lastPage = pagination.lastPage + 1;
        this.tableConfig.pagination.total = pagination.total;
        this.tableConfig.pagination.pageTotal = items?.length || 0;

        this.isLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
      });
  }

  onPaginationChanged() {
    this.onFilter();
  }
}
