
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import Section from "@/components/Section/Section.vue";
import BodyHeader from "@/components/BodyHeader/BodyHeader.vue";
import {
  InputComponent,
  SelectComponent,
  UploadComponent,
  ControlLabelComponent,
  ButtonComponent,
  BadgeComponent,
  MessageComponent,
} from "@procesio/procesio-design-system";
import {
  validateFile as vFile,
  uploadConsignment,
  getStatus,
} from "../services/Consignment";
import { getToken } from "../services/Token";
import Modal from "../components/Modal/Modal.vue";

import PulseLoader from "vue-spinner/src/PulseLoader.vue";

@Component({
  components: {
    Section,
    BodyHeader,
    Modal,
    "pds-input": InputComponent,
    "pds-select": SelectComponent,
    "pds-upload": UploadComponent,
    "pds-label": ControlLabelComponent,
    "pds-button": ButtonComponent,
    "pds-badge": BadgeComponent,
    "pds-message": MessageComponent,
    "pulse-loader": PulseLoader,
  },
})
export default class TransportView extends Vue {
  form: {
    consignment: string;
    id: string;
    transportType: string;
    operationScope: string;
    partnerCode: string;
    partnerName: string;
    file: File | null;
    no: string;
  } = {
    consignment: "3081843",
    id: "4230852",
    transportType: "Transport pe teritoriul national",
    operationScope: "",
    partnerCode: "",
    partnerName: "",
    file: null,
    no: "",
  };

  @Watch("form.file", { immediate: true, deep: true })
  onFormChange() {
    this.isValid = null;
    this.isValidated = false;
  }

  isPageLoading = false;

  isTokenSet = false;

  isValid: boolean | null = null;

  isValidated = false;

  isValidating = false;

  isSubmitted = false;

  isSubmitting = false;

  canBeEdited = true;

  clientId = "";

  isModalOn = {
    token: false,
  };

  operationOptions = [
    { value: "300101", name: "Comercializare" },
    { value: "300704", name: "Transfer intre gestiuni" },
    { value: "300705", name: "Bunuri puse la dispozitia clientului" },
  ];

  me = {
    name: "",
    code: "",
  };

  errorMessages: Array<{ code: number; message: string }> = [];

  uit = "";

  get canValidateFile() {
    return this.isTokenSet;
  }

  get canSubmit() {
    return this.isTokenSet && this.isValid;
  }

  downloads = [
    {
      copy: "Descarca Template_fisier_transport_paleti.xlsx",
      link: "https://drive.google.com/uc?export=download&id=1kgx6UATsWnnpzyaWPSzROwj123wXGCIj",
      icon: "",
    },
    {
      copy: "Deschide Nomenclator coduri tarifare.pdf",
      link: "https://drive.google.com/uc?export=download&id=1pP5fMfPvO57Pvfaz3hGOHD9dfJ0H2alj",
      icon: "",
    },
  ];

  mounted() {
    const { palletno, palletid, consignmentid, clientid, credentials } =
      this.$route.query;

    this.form.consignment = consignmentid as string;
    this.form.id = palletid as string;
    this.form.no = palletno as string;
    this.clientId = clientid as string;

    const payload = { ClientId: this.clientId };

    this.isPageLoading = true;

    getToken(payload, "TokenDetails").then((resp: any) => {
      this.isTokenSet = !!resp;
      this.isModalOn.token = !this.isTokenSet;

      if (this.isTokenSet) {
        this.me.code = resp.cui;
        this.me.name = resp.denumire_firma;

        const statusPayload = {
          IdPartidaClientPlus: this.form.consignment,
          NrPalet: this.form.no,
        };

        getStatus(statusPayload, ["Uit", "CanBeEdited"]).then((resp: any) => {
          this.canBeEdited = resp[0];

          const uit = resp[1];

          if (uit) {
            this.uit = uit;
          }

          this.isPageLoading = false;
        });
      } else {
        this.isPageLoading = false;
      }
    });
  }

  handleUpload(event: any) {
    const path = event.path || (event.composedPath && event.composedPath());
    const file = path[0].files[0];

    this.form.file = file;
  }

  deleteFile() {
    this.form.file = null;
  }

  validateFile() {
    this.isValidated = true;
    this.isValidating = true;

    if (!this.form.file) {
      this.isValidating = false;
      return;
    }

    const payload = { FisierBunuri: this.form.file };

    vFile(payload, "ErrorMessages").then((resp: any) => {
      if (Array.isArray(resp)) {
        this.isValid = resp.length == 0;

        if (this.isValid) {
          this.errorMessages = [];
        } else {
          this.errorMessages = [...resp];
        }
      } else {
        this.isValid = true;
        this.errorMessages = [];
      }

      this.isValidating = false;
    });
  }

  submit() {
    this.isSubmitted = true;
    this.isSubmitting = true;

    const isValid = Object.values(this.form)
      .map((val) => !!val)
      .every((v) => v === true);

    if (!isValid) {
      this.isSubmitting = false;
      return;
    }

    const payload = {
      ClientId: this.clientId,
      CodScopOperatiune: this.form.operationScope,
      CUIDeclarant: this.me.code,
      CUIPartenerComercial: this.form.partnerCode,
      DenumirePartenerComercial: this.form.partnerName,
      FisierBunuri: this.form.file,
      IdPartidaClientPlus: this.form.consignment,
      NrPalet: this.form.no,
      NumeDeclarant: this.me.name,
    };

    uploadConsignment(payload).then((resp) => {
      this.isSubmitting = false;

      this.$toastr(
        "success",
        "Detaliile paletului au fost trimise spre salvare.",
        "succes"
      );

      setTimeout(() => {
        this.backToPallex();
      }, 3000);
    });
  }

  backToPallex() {
    window.location.assign("https://clientplus.pallex.ro/pallets");
  }

  closeModal() {
    this.isModalOn.token = false;
  }

  goToTokenPage() {
    this.$router.push({ path: "token", query: this.$route.query });
  }
}
