var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"transport"},[_c('BodyHeader',{attrs:{"name":"SPV Token","description":"Seteaza aici Token-ul de access obtinut din SPV."}}),_c('div',{staticClass:"half pds-u-p--r--16"},[_c('pds-input',{staticClass:"grow-max pds-u-m--b--16",attrs:{"label":"Nume companie","placeholder":"Adauga Nume companie","required":true,"status":{
        type: 'danger',
        message: [
          ...(_vm.isSubmitted && !_vm.form.companyName
            ? ['Acest camp este obligatoriu']
            : []),
        ],
      }},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}}),_c('pds-input',{staticClass:"grow-max pds-u-m--b--16",attrs:{"label":"CUI","placeholder":"Adauga CUI","required":true,"type":"number","tooltip":"Valoarea pentru CUI trebuie sa fie formata doar din cifre.","status":{
        type: 'danger',
        message: [
          ...(_vm.isSubmitted && !_vm.form.companyCode
            ? ['Acest camp este obligatoriu']
            : []),
        ],
      }},model:{value:(_vm.form.companyCode),callback:function ($$v) {_vm.$set(_vm.form, "companyCode", $$v)},expression:"form.companyCode"}}),_c('pds-input',{staticClass:"grow-max",attrs:{"label":"Email contact","placeholder":"Adauga Email contact","required":true,"status":{
        type:
          _vm.isSubmitted && (!_vm.form.email || _vm.email(_vm.form.email))
            ? 'danger'
            : 'info',
        message: [
          ...(_vm.isSubmitted && (!_vm.form.email || _vm.email(_vm.form.email))
            ? [
                !_vm.form.email
                  ? 'Acest camp este obligatoriu'
                  : 'Acest email este invalid',
              ]
            : [
                'Folosit pentru a primi notificari pe email atunci cand Tokenul devine invalid. Pot fi adaugate adrese multiple separate cu ‘;’ .',
              ]),
        ],
      }},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"half"},[_c('pds-input',{staticClass:"grow-max pds-u-m--b--16",attrs:{"label":"Access token","placeholder":"Adauga Access token","required":true,"status":{
        type: 'danger',
        message: [
          ...(_vm.isSubmitted && !_vm.form.accessToken
            ? ['Acest camp este obligatoriu']
            : []),
        ],
      }},model:{value:(_vm.form.accessToken),callback:function ($$v) {_vm.$set(_vm.form, "accessToken", $$v)},expression:"form.accessToken"}}),_c('pds-input',{staticClass:"grow-max pds-u-m--b--16",attrs:{"label":"Refresh token","placeholder":"Adauga Refresh token","required":true,"status":{
        type: 'danger',
        message: [
          ...(_vm.isSubmitted && !_vm.form.refreshToken
            ? ['Acest camp este obligatoriu']
            : []),
        ],
      }},model:{value:(_vm.form.refreshToken),callback:function ($$v) {_vm.$set(_vm.form, "refreshToken", $$v)},expression:"form.refreshToken"}}),_c('pds-input',{staticClass:"grow-max pds-u-m--b--16",attrs:{"label":"Client ID","placeholder":"Adauga Client ID","required":true,"status":{
        type: 'danger',
        message: [
          ...(_vm.isSubmitted && !_vm.form.clientId
            ? ['Acest camp este obligatoriu']
            : []),
        ],
      }},model:{value:(_vm.form.clientId),callback:function ($$v) {_vm.$set(_vm.form, "clientId", $$v)},expression:"form.clientId"}}),_c('pds-input',{staticClass:"grow-max",attrs:{"label":"Client Secret","placeholder":"Adauga Client Secret","required":true,"status":{
        type: 'danger',
        message: [
          ...(_vm.isSubmitted && !_vm.form.clientSecret
            ? ['Acest camp este obligatoriu']
            : []),
        ],
      }},model:{value:(_vm.form.clientSecret),callback:function ($$v) {_vm.$set(_vm.form, "clientSecret", $$v)},expression:"form.clientSecret"}}),(_vm.errorMessage && _vm.errorCode && _vm.errorCode > 0)?_c('pds-message',{staticClass:"pds-u-m--t--32",attrs:{"text":_vm.errorMessage,"type":"error"}}):_vm._e()],1),_c('div',{staticClass:"pds-u-m--t--48 btn",staticStyle:{"width":"100%"}},[(!_vm.isValidating)?_c('pds-button',{staticClass:"btn-orange",attrs:{"type":"solid","color":"primary","icon":"check"},on:{"click":_vm.validateToken}},[_vm._v("Valideaza")]):_c('span',{staticStyle:{"width":"180px"}},[_c('pulse-loader',{staticClass:"pds-u-m--l--12"})],1),(_vm.isValidated && !_vm.isValidating)?_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticStyle:{"margin":"0 8px 0 16px"}},[_vm._v("Status: ")]),(_vm.isValid)?_c('span',[_c('pds-badge',{attrs:{"label":"Valid","type":2}})],1):_c('span',[_c('pds-badge',{attrs:{"label":"Invalid","type":4}})],1)]):_vm._e()],1),_c('div',{staticClass:"pds-u-m--t--12 btn",staticStyle:{"width":"100%","display":"flex","justify-content":"flex-end"}},[_c('pds-button',{staticClass:"pds-u-m--r--12 btn-grey",attrs:{"type":"solid","color":"primary","icon":"subdirectory_arrow_left","isIconOutlined":true},on:{"click":_vm.backToPallex}},[_vm._v("Inapoi la Pall-Ex")]),(!_vm.isSubmitting)?_c('pds-button',{staticClass:"btn-orange",attrs:{"type":"solid","color":"primary","icon":"save","isIconOutlined":true,"disabled":!_vm.isValid},on:{"click":_vm.submit}},[_vm._v("Salveaza")]):_c('span',{staticStyle:{"width":"120px"}},[_c('pulse-loader',{staticClass:"pds-u-m--l--12"})],1)],1),(_vm.isPageLoading)?_c('Modal',{attrs:{"title":"Loading","hasButtons":false}},[_c('pulse-loader')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }