
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import Section from "@/components/Section/Section.vue";
import BodyHeader from "@/components/BodyHeader/BodyHeader.vue";
import {
  InputComponent,
  SelectComponent,
  UploadComponent,
  ControlLabelComponent,
  ButtonComponent,
  BadgeComponent,
  MessageComponent,
} from "@procesio/procesio-design-system";

import { getToken, saveToken, validateToken } from "../services/Token";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Modal from "@/components/Modal/Modal.vue";

@Component({
  components: {
    Section,
    BodyHeader,
    Modal,
    "pds-input": InputComponent,
    "pds-select": SelectComponent,
    "pds-upload": UploadComponent,
    "pds-label": ControlLabelComponent,
    "pds-button": ButtonComponent,
    "pds-badge": BadgeComponent,
    "pds-message": MessageComponent,
    "pulse-loader": PulseLoader,
  },
})
export default class TransportView extends Vue {
  form: {
    companyName: string;
    companyCode: string;
    email: string;
    accessToken: string;
    refreshToken: string;
    clientId: string;
    clientSecret: string;
  } = {
    companyName: "",
    companyCode: "",
    email: "",
    accessToken: "",
    refreshToken: "",
    clientId: "",
    clientSecret: "",
  };

  @Watch("form", { immediate: true, deep: true })
  onFormChange() {
    this.isValid = false;
    this.isValidated = false;
  }

  isPageLoading = false;

  isValid = false;

  isValidated = false;

  isValidating = false;

  isSubmitted = false;

  isSubmitting = false;

  errorMessage = "";
  errorCode: number | null = null;

  clientId = "";

  mounted() {
    // const { palletno, palletid, cosignmentid, guid, clientid, credentials } =
    const { clientid } = this.$route.query;
    this.clientId = clientid as string;

    const payload = { ClientId: this.clientId };
    this.isPageLoading = true;
    getToken(payload, "TokenDetails").then((resp: any) => {
      this.form = {
        ...this.form,
        companyName: resp?.denumire_firma ? resp.denumire_firma : "",
        companyCode: resp?.cui ? resp.cui : "",
        email: resp?.emails ? resp.emails : "",
        accessToken: resp?.access_token ? resp.access_token : "",
        refreshToken: resp?.refresh_token ? resp.refresh_token : "",
        clientId: resp?.client_id ? resp.client_id : "",
        clientSecret: resp?.client_secret ? resp.client_secret : "",
      };
      this.isPageLoading = false;
    });
  }

  validateToken() {
    this.isSubmitted = true;
    this.isValidating = true;

    const isFormValid = this.validateForm(this.form);

    if (!isFormValid || this.email(this.form.email)) {
      this.isValidating = false;
      return;
    }

    const payload = {
      access_token: this.form.accessToken,
      client_id: this.form.clientId,
      client_secret: this.form.clientSecret,
      cui: this.form.companyCode,
      denumire_firma: this.form.companyName,
      emails: this.form.email,
      refresh_token: this.form.refreshToken,
    };

    validateToken({ TokenDetails: payload }, [
      "ValidationResultCode",
      "ValidationResultMessage",
    ]).then((resp: unknown) => {
      if (Array.isArray(resp)) {
        const code = resp[0];
        const message = resp[1];

        this.errorCode = code;
        this.isValid = code == 0;
        this.errorMessage = message;
      }

      this.isValidated = true;
      this.isValidating = false;
    });
  }

  validateForm(form: Record<string, unknown>) {
    return Object.values(form)
      .map((val) => !!val)
      .every((v) => v === true);
  }

  submit() {
    this.isSubmitted = true;
    this.isSubmitting = true;

    const isValid = this.validateForm(this.form);

    if (!isValid) {
      this.isSubmitting = false;
      return;
    }

    const payload = {
      access_token: this.form.accessToken,
      client_id: this.form.clientId,
      client_secret: this.form.clientSecret,
      cui: this.form.companyCode,
      denumire_firma: this.form.companyName,
      emails: this.form.email,
      refresh_token: this.form.refreshToken,
    };

    saveToken({ TokenDetails: payload, ClientId: this.clientId }).then(() => {
      this.isSubmitting = false;

      this.$toastr(
        "success",
        "Credentialul a fost trimis spre salvare",
        "succes"
      );
    });
  }

  backToPallex() {
    window.location.assign("https://clientplus.pallex.ro/pallets");
  }

  email(val: string) {
    const EMAIL_REGEXP =
      // eslint-disable-next-line no-useless-escape
      /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;

    function isEmptyInputValue(value: any) {
      // we don't check for string here so it also works with arrays and boolean
      return value == null || value.length === 0 || value === false;
    }

    if (isEmptyInputValue(val)) {
      return null; // don't validate empty values to allow optional controls
    }
    return EMAIL_REGEXP.test(val) ? null : "Not a valid email";
  }
}
