/* eslint-disable prettier/prettier */

import { runProcessAndGetVar } from "./ProcesioSDK";

export const validateFile = (
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) => {
  return runProcessAndGetVar(
    "f9ec775c-1c48-4980-9db0-15ace6e606e0",
    "bf42bea0-02fb-4f5e-bed4-0d3f5f23eafb",
    {
      name: "5tbVG4smpfIKDRoY",
      value: "sZdeqxQEwbNYnETlCiry26SD49DbFix50JhskJEjL8mtDCQgwhTSX3HArtkKangI",
    },
    payload,
    output
  );
};

export const uploadConsignment = (
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) => {
  return runProcessAndGetVar(
    "e4cc39f4-9550-4cc4-8d3d-2e29ec1424bf",
    "bf42bea0-02fb-4f5e-bed4-0d3f5f23eafb",
    {
      name: "5tbVG4smpfIKDRoY",
      value: "sZdeqxQEwbNYnETlCiry26SD49DbFix50JhskJEjL8mtDCQgwhTSX3HArtkKangI",
    },
    payload,
    output
  );
};

export const getStatus = (
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) => {
  return runProcessAndGetVar(
    "983dc6e1-9aaa-47d5-987c-76f776a9e41f",
    "bf42bea0-02fb-4f5e-bed4-0d3f5f23eafb",
    {
      name: "5tbVG4smpfIKDRoY",
      value: "sZdeqxQEwbNYnETlCiry26SD49DbFix50JhskJEjL8mtDCQgwhTSX3HArtkKangI",
    },
    payload,
    output
  );
};

export const filterUIT = (
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) => {
  return runProcessAndGetVar(
    "ea1deae9-94dd-4abe-801e-1bc414a45c97",
    "bf42bea0-02fb-4f5e-bed4-0d3f5f23eafb",
    {
      name: "5tbVG4smpfIKDRoY",
      value: "sZdeqxQEwbNYnETlCiry26SD49DbFix50JhskJEjL8mtDCQgwhTSX3HArtkKangI",
    },
    payload,
    output
  );
};
