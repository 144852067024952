
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component({ components: {} })
export default class Section extends Vue {
  @Prop() number!: string;

  @Prop() name!: string;

  @Prop() tooltip?: string;
}
