
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { IconComponent } from "@procesio/procesio-design-system";

@Component({ components: { "pds-icon": IconComponent } })
export default class BodyHeader extends Vue {
  @Prop() name!: string;
  @Prop() description!: string;
  @Prop() downloads?: Array<{ copy: string; link: string; icon: string }>;
}
