
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

import {
  IconComponent,
  ButtonComponent,
} from "@procesio/procesio-design-system";

@Component({
  components: { "pds-icon": IconComponent, "pds-button": ButtonComponent },
})
export default class Modal extends Vue {
  @Prop() title?: string;

  @Prop({ default: true }) hasButtons?: boolean;

  @Emit("close")
  closeModal() {
    return;
  }
}
