/* eslint-disable prettier/prettier */

import { runProcessAndGetVar } from "./ProcesioSDK";

export const getToken = (
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) => {
  return runProcessAndGetVar(
    "6762ada7-77ee-4ca8-9c41-5f770e95e733",
    "9ca015b2-3b9f-4d22-93d0-27a069aada33",
    {
      name: "i0zX9c3WbY0YYQm3",
      value: "oTdOWfhGnsnwAbuPYqmqIfS9nmcNC1SRWV7f1BMcyraIrmBiUwBZJizbN8mgKPMx",
    },
    payload,
    output
  );
};

export const saveToken = (
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) => {
  return runProcessAndGetVar(
    "99b5833a-5a78-4f41-a55b-ae424f25696c",
    "9ca015b2-3b9f-4d22-93d0-27a069aada33",
    {
      name: "i0zX9c3WbY0YYQm3",
      value: "oTdOWfhGnsnwAbuPYqmqIfS9nmcNC1SRWV7f1BMcyraIrmBiUwBZJizbN8mgKPMx",
    },
    payload,
    output
  );
};

export const validateToken = (
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) => {
  return runProcessAndGetVar(
    "35ef5cd6-7a3f-4f10-a1b5-104d5b4febc9",
    "9ca015b2-3b9f-4d22-93d0-27a069aada33",
    {
      name: "i0zX9c3WbY0YYQm3",
      value: "oTdOWfhGnsnwAbuPYqmqIfS9nmcNC1SRWV7f1BMcyraIrmBiUwBZJizbN8mgKPMx",
    },
    payload,
    output
  );
};
