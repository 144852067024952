/* eslint-disable prettier/prettier */
import { RequestMethods, request } from "./Request";

export interface FileWrapper {
  package: File;
  fileId?: string;
}

export type FileName = Record<"name", string>;

export interface ProcessInstance {
  id: string;
  title: string;
  description: string;
  isValid: boolean;
  firstName: string;
  lastName: string;
  workspaceName: string;
  variables: Array<Variable>;
  status: number;
  instanceId: string;
}

export interface Variable {
  id: string;
  contextId: string;
  name: string;
  dataType: string;
  defaultValue: unknown | FileDefaultValue | FileDefaultValue[];
  isList: boolean;
  type: VariableType;
}

export interface FileDefaultValue {
  name: string;
  id: string;
}

export enum VariableType {
  INPUT = 10,
  OUTPUT = 30,
  PROCESS = 20,
}

export interface ApiKeyCredential {
  name: string;
  value: string;
}

export async function runProcess(
  processId: string,
  inputValues: Record<string, unknown | File | FileList>,
  isSynchronous = false,
  workspace: string,
  apikey: ApiKeyCredential
) {
  let hasFiles = false;

  const files: Record<string, FileWrapper | FileWrapper[]> = {};

  const parsedPayload = Object.entries(inputValues).reduce(
    (acc: Record<string, unknown | FileName | FileName[]>, [key, value]) => {
      if (value instanceof File) {
        hasFiles = true;

        acc[key] = { name: value.name };

        files[key] = { package: value };
      } else if (value instanceof FileList) {
        hasFiles = true;

        const fileArray = Array.from(value);

        acc[key] = fileArray.map((file) => ({ name: file.name }));

        files[key] = fileArray.map((file) => ({ package: file }));
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  const publishReq = await publishProcess(
    processId,
    parsedPayload,
    workspace,
    apikey
  );

  if (!publishReq.isError && publishReq.content) {
    const instance = publishReq.content;

    if (hasFiles) {
      for (const variableName in files) {
        if (Object.prototype.hasOwnProperty.call(files, variableName)) {
          const fileWrapper = files[variableName];

          const defaultValue = instance.variables.find(
            (variable) => variable.name === variableName
          )?.defaultValue;

          if (defaultValue) {
            if (Array.isArray(fileWrapper)) {
              fileWrapper.forEach((wrapper, index) => {
                wrapper.fileId = (defaultValue as FileDefaultValue[])[index].id;
              });
            } else {
              fileWrapper.fileId = (defaultValue as FileDefaultValue).id;
            }
          }
        }
      }

      const promises: Array<Response> = [];

      for (const variableName in files) {
        if (Object.prototype.hasOwnProperty.call(files, variableName)) {
          const element = files[variableName] as FileWrapper;

          if (Array.isArray(element)) {
            for (let index = 0; index < element.length; index++) {
              promises.push(
                await uploadFile(
                  instance.id,
                  variableName,
                  element[index].fileId,
                  element[index].package,
                  workspace,
                  apikey,
                  processId
                )
              );
            }
          } else {
            promises.push(
              await uploadFile(
                instance.id,
                variableName,
                element.fileId as string,
                element.package as unknown as string,
                workspace,
                apikey,
                processId
              )
            );
          }
        }
      }

      return Promise.all(promises).then(() => {
        return launchProcessInstance(
          instance.id,
          isSynchronous,
          workspace,
          apikey,
          processId
        );
      });
    } else {
      return launchProcessInstance(
        instance.id,
        isSynchronous,
        workspace,
        apikey,
        processId
      );
    }
  }
}

export function publishProcess(
  processId: string,
  inputValues: Record<string, unknown | FileName | FileName[]>,
  workspace: string,
  apikey: ApiKeyCredential
) {
  return request<ProcessInstance>({
    base: "https://webapi.procesio.app",
    url: `Projects/${processId}/instances/publish`,
    apiKey: apikey,
    workspace,
    body: inputValues,
    method: RequestMethods.POST,
  });
}

export async function uploadFile(
  instanceId: string,
  variableName: string,
  fileId: string,
  file: string,
  workspace: string,
  apikey: ApiKeyCredential,
  processId: string
) {
  const headers = new Headers();

  headers.set("Accept", "application/json");

  if (apikey?.name && apikey?.value) {
    headers.set("key", apikey.name);
    headers.set("value", apikey.value);
  }

  headers.set("realm", "procesio01");

  headers.set("flowInstanceId", instanceId);

  headers.set("variableName", variableName);

  headers.set("fileId", fileId);

  headers.set("flowTemplateId", processId);

  headers.set("workspaceId", workspace);

  const formData = new FormData();

  formData.append("package", file);

  headers.delete("Content-Type");
  delete (headers as any)["Content-Type"];

  const resp = await fetch(`https://webapi.procesio.app/api/file/upload/flow`, {
    method: "POST",
    headers,
    body: formData,
  });

  return resp.json();
}

export async function launchProcessInstance(
  instanceId: string,
  isSynchronous = false,
  workspace: string,
  apikey: ApiKeyCredential,
  processId: string
) {
  const syncQuery = isSynchronous ? "?runSynchronous=true" : "";

  return request<{ instanceId: string } | ProcessInstance>({
    base: "https://webapi.procesio.app",
    url: `Projects/instances/${instanceId}/launch${syncQuery}`,

    apiKey: apikey,
    workspace,
    body: { connectionId: "", flowTemplateId: processId },
  });
}

export async function getStatus(
  instanceId: string,
  workspace: string,
  apikey: ApiKeyCredential,
  processId: string
) {
  return request<Record<"instance", ProcessInstance>>({
    base: "https://webapi.procesio.app",
    url: `projects/instances/${instanceId}/status?flowTemplateId=${processId}`,
    apiKey: apikey,
    workspace,
    method: RequestMethods.GET,
  });
}

export async function runProcessAndGetVar(
  processId: string,
  workspace: string,
  apikey: ApiKeyCredential,
  payload: Record<string, unknown | File | FileList>,
  output?: string | string[]
) {
  return runProcess(processId, payload, false, workspace, apikey).then(
    async (resp) => {
      if (!output) {
        return resp;
      }

      if (resp?.content?.instanceId) {
        return new Promise(function (resolve) {
          const intervalTimer = setInterval(async () => {
            if (resp?.content?.instanceId) {
              const statusReq = await getStatus(
                resp.content.instanceId,
                workspace,
                apikey,
                processId
              );

              const isDone = statusReq.content?.instance.status == 50;

              if (isDone) {
                clearInterval(intervalTimer);

                const vars = findVariables(
                  output,
                  statusReq.content?.instance.variables
                );
                resolve(vars);
              }
            }
          }, 2000);
        });
      }
    }
  );
}

const findVariables = (
  output: string | string[],
  variables?: Array<Variable>
) => {
  if (Array.isArray(output)) {
    return variables
      ?.filter((variable) => output.includes(variable.name))
      .map((variable) => variable.defaultValue);
  }

  return variables?.find((variable) => output === variable.name)?.defaultValue;
};
