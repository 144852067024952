import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import TransportView from "../views/TransportView.vue";
import UITView from "../views/UITView.vue";
import TokenView from "../views/TokenView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Incarca fisier de transport",
    component: TransportView,
  },
  {
    path: "/token",
    name: "Token Vault",
    component: TokenView,
  },
  {
    path: "/tracking",
    name: "UIT Tracker",
    component: UITView,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ErrorView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
